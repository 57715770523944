import React, { ReactElement } from "react"

interface IListItem {
  title?: string
  html?: string
}

interface IPageData {
  heading?: string
  list?: IListItem[]
}

interface IPageProps {
  Data?: IPageData
}

function PageContainer({ Data = {} }: IPageProps): ReactElement {
  const { heading, list } = Data
  return (
    <>
      <div
        className="min-h-screen w-screen bg-right    bg-cover md:bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${require("../../images/clouds-bg.svg").default})`,
        }}
      >
        <div className="pt-32">
          <div className="text-3xl text-blue-800 flex justify-center">
            {heading}
          </div>
          <div className="text-xl border-b-2 text-blue-800 flex flex-col justify-start  p-4 md:w-3/4 mx-auto">
            {list && list.length
              ? list.map((li: IListItem, index: number) => (
                  <div key={index}>
                    <a href={"#" + "__link_" + li.title}>
                      {index + 1}. {li.title}
                    </a>
                  </div>
                ))
              : ""}
          </div>
          <div className=" text-blue-800 flex-col justify-start  p-4 md:w-3/4 mx-auto">
            {list && list.length
              ? list.map((li: IListItem, index: number) => (
                  <div
                    className="my-8"
                    id={"__link_" + li.title}
                    key={"a" + index}
                  >
                    <div className="text-lg my-4">
                      <a href={"#" + "__link_" + li.title}>
                        {index + 1}. {li.title}
                      </a>
                    </div>
                    <div className="text-sm">
                      <span
                        dangerouslySetInnerHTML={{ __html: li.html as any }}
                      />
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div>
    </>
  )
}

export default PageContainer
